<template>
  <div v-show="show" class="wrapper--loading">
    <div class="loading--img-wrapper">
      <img
        src="https://rentfixs3-sg.s3.ap-southeast-1.amazonaws.com/public/static/img/logo/icon.png"
        width="50"
        alt="Loading"
        class="loading--img"
      />
      <v-progress-circular :size="150" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
